import { Box, Grid, Paper } from "@mui/material";
import React, { useState, useEffect } from 'react';
import styled from "@emotion/styled";
import { CustomTypography } from "./ui/custom-typography";
import Faceta from '../assets/images/icons/faceta-dentaria.png';
import Coroa from '../assets/images/icons/coroa.png';
import Implante from '../assets/images/icons/implante-dentario.png';
import Canal from '../assets/images/icons/canal.png';
import Cirurgia from '../assets/images/icons/cirurgia.png';
import Cuidado from '../assets/images/icons/cuidado.png';
import Pediatra from '../assets/images/icons/pediatria.png';
import Acido from '../assets/images/icons/acido.png';
import Injecao from '../assets/images/icons/injecao.png';

const Item = styled(Paper)(({ theme }) => ({
    padding: 10,
    textAlign: 'center',
    color: '#07092B',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    boxSizing: 'border-box'
}));

export const Procedures = () => {
    const [iconWidth, setIconWidth] = useState(window.innerWidth * 0.045);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 600) setIconWidth(window.innerWidth * 0.12);
            else setIconWidth(window.innerWidth * 0.05);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const procedures: [string, string][] = [
        ["Facetas e Lentes", Faceta],
        ["Coroas, Pontes e Blocos", Coroa],
        ["Coroa sobre Implante e Implantes Unitários", Implante],
        ["Tratamento de Canal", Canal],
        ["Cirurgias minimamente invasivas", Cirurgia],
        ["Gengivoplastia e Gengivectomia", Cuidado],
        ["Atendimento Odontopediátrico", Pediatra],
        ["Preenchimentos com Ácido Hialurônico", Acido],
        ["Aplicação de Toxina Botulínica", Injecao]
    ];

    return (
        <Box sx={{ height: 'auto', width: '100%' }}>
            <Grid container spacing={1.2}>
                {procedures.map((proc, index) => (
                    <Grid item xs={6} sm={4} md={4} lg={3} xl={4} key={index}>
                        <Item sx={{ height: 150 }}>
                            <img src={proc[1]} alt={proc[0]} width={iconWidth} height={iconWidth} />
                            <CustomTypography fontWeight={700}>{proc[0]}</CustomTypography>
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
