import { MapsComponent } from "./maps";
import { FlexContainer } from "./ui/flex-container";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NearMeIcon from '@mui/icons-material/NearMe';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Box, SvgIconProps, SvgIconTypeMap, Typography } from "@mui/material";

interface IconFormatedProps {
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
    sx?: SvgIconProps['sx'];
    onClickFunction?: () => void;
}

const LocaleTitleTypography: React.FC<{ text: string, title: string }> = ({ text, title }) => {
    return (
        <Typography
            component='div'
            sx={{
                fontWeight: '300',
                fontSize: {
                    xs: '17px',
                    sm: '18px',
                    md: '22px',
                    lg: '26px',
                    xl: '30px',
                },
                display: 'inline',
                width: '100%'
            }}
        >
            <Box
                sx={{
                    marginRight: '1%',
                    fontWeight: '700',
                    fontSize: {
                        xs: '17px',
                        sm: '20px',
                        md: '24px',
                        lg: '28px',
                        xl: '32px',
                    },
                    display: 'inline'
                }}
            >
                {title}
            </Box>
            {text}
        </Typography>
    );
};

const IconFormated: React.FC<IconFormatedProps> = ({ Icon, sx, onClickFunction }) => {
    return (
        <Icon
            sx={{
                marginRight: '1%',
                fontSize: {
                    xs: '25px',
                    sm: '28px',
                    md: '30px',
                    lg: '32px',
                    xl: '34px',
                },
                ...sx
            }}
            onClick={onClickFunction}
        />
    );
};


export const LocaleSection: React.FC = () => {  
    const handleLinkClick = (link: string) => {
        const url = link;
        window.open(url, '_blank');
    };

    return (
        <FlexContainer
            id="locale"
            sx={{
                height: { xs: '50vh', md: '60vh', lg: '60vh', xl: '50vh' },
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: { xs: 'column', xl: 'row' },
                background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(27,44,72,1) 100%)',
                padding: { xs: '10% 0%', sm: '5% 5%', md: '3% 5%', lg: '3% 5%', xl: '1% 5%' },
                boxSizing: 'border-box'
            }}
        >

            <FlexContainer sx={{ width: { xs: '90%', md: '80%', lg: '60%', xl: '45%' }, height: { xs: '50%', xl: '70%' } }}>
                <MapsComponent />
            </FlexContainer>
            <FlexContainer
                sx={{
                    flexDirection: 'column',
                    color: 'white',
                    width: { xs: '90%', md: '80%', lg: '60%', xl: '45%' },
                    justifyContent: 'center',
                    height: { xs: '60%', xl: '100%' }
                }}
            >
                <FlexContainer
                    sx={{
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: { xs: 'center', xl: 'flex-start' },
                        height: { xs: '100%', xl: '60%' },
                    }}
                >
                    <FlexContainer alignItems={'center'}>
                        <IconFormated Icon={LocationOnIcon} />
                        <LocaleTitleTypography text="Rua Ator Paulo Gustavo, 26, Trade Center, Sala 717" title="Nossa localização:" />
                    </FlexContainer>
                    {/* <FlexContainer alignItems={'center'}>
                        <IconFormated Icon={CalendarMonthIcon} />
                        <LocaleTitleTypography text="Sábados" title="Dias de atendimento:" />
                    </FlexContainer> */}
                    <FlexContainer alignItems={'center'}>
                        <IconFormated Icon={DisabledByDefaultIcon} />
                        <LocaleTitleTypography text="" title="Não aceitamos planos odontológicos" />
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer sx={{ height: '10%', justifyContent: 'start', alignItems: 'center' }}>
                    <IconFormated onClickFunction={() => handleLinkClick('https://www.instagram.com/roger.nabarro/')} Icon={InstagramIcon} sx={{ cursor: 'pointer' }} />
                    <IconFormated onClickFunction={() => handleLinkClick('https://wa.me/5521993302613')} Icon={WhatsAppIcon} sx={{ cursor: 'pointer' }} />
                    <IconFormated onClickFunction={() => handleLinkClick('https://maps.app.goo.gl/zP15LdL1CR6RWuFp9')} Icon={NearMeIcon} sx={{ cursor: 'pointer' }} />
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};
