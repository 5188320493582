import BackgroundImage from '../assets/images/roger/initial.png';
import { FlexContainer } from "./ui/flex-container";
import { CustomTypography } from "./ui/custom-typography";
import { WhatsappButton } from './whatsapp/whatsapp-button';

const HomeSection = () => {
    return (
        <FlexContainer
            id="home"
            sx={{
                height: { xs: '95vh', sm: '85vh', md: '85vh', lg: '85vh' },
                justifyContent: { xs: 'flex-start', lg: 'flex-end' },
                alignItems: 'center',
            }}
        >
            <FlexContainer
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                <FlexContainer sx={{
                    width: '100%',
                    padding: '2%',
                    flexDirection: { xs: 'column', sm: 'row', lg: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <FlexContainer sx={{ width: { xs: '65%', sm: '400px', md: '400px', lg: '400px', xl: '400px' }, marginRight: '5%' }}>
                        <img
                            src={BackgroundImage}
                            loading="lazy"
                            style={{ borderRadius: '5%', width: '100%' }}
                        />
                    </FlexContainer>
                    <FlexContainer
                        sx={{ flexDirection: 'column', width: { xs: '80%', md: '500px', lg: '550px' }, marginTop: { xs: '5%', sm: '4%', md: '2%', lg: '0%' } }}
                    >
                        <CustomTypography
                            sx={{
                                fontSize: { xs: '25px', md: '30px', lg: '42px' },
                                fontWeight: '700',
                                marginBottom: '3%',
                                lineHeight: '1.2',
                                textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'start', xl: 'start' },
                                color: 'black',
                            }}
                        >
                            Nabarro Odontologia Estética & Reabilitação Oral
                        </CustomTypography>
                        <CustomTypography
                            sx={{
                                fontSize: { xs: '1.2em', sm: '1.4em', md: '1.6em', lg: '1.7em', xl: '1.7em' },
                                fontWeight: '400',
                                color: 'black',
                                textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'start', xl: 'start' },
                                marginBottom: '4%',
                            }}
                        >
                            Redescubra a confiança de um sorriso saudável, com técnicas avançadas validadas pela ciência.
                        </CustomTypography>
                        <FlexContainer
                            sx={{
                                alignSelf: { xs: 'center', sm: 'center', md: 'center', lg: 'start' },
                            }}>
                            <WhatsappButton />
                        </FlexContainer>
                    </FlexContainer>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    );
};

export default HomeSection