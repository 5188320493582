import { Divider } from "@mui/material"
import { FlexContainer } from "./ui/flex-container"
import RogerImage from "../assets/images/roger/roger-why.jpg"
import { CustomTypography } from "./ui/custom-typography"

export const WhySection = () => {

    return (
        <FlexContainer
            id="why"
            sx={{
                height: 'auto',
                paddingY: '5%',
                width: '100%',
                background: 'linear-gradient(0deg, rgba(255,255,255,1) 90%, rgba(27,44,72,1) 115%)',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <FlexContainer
                sx={{
                    width: { xs: '80%', md: '70', lg: '60%' },
                    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(27,44,72,1) 100%)',
                    height: 'auto',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                    borderRadius: 2,
                    flexDirection: { xs: 'column', md: 'column', lg: 'column', xl: 'row' },
                    padding: { xs: '4%', md: '4%', lg: '3%', xl: '2%' },
                    justifyContent: 'space-around'
                }}>
                <FlexContainer sx={{
                    flexDirection: 'column',
                    width: { lg: '100%', xl: '50%' }
                }}>
                    <FlexContainer
                        sx={{
                            flexDirection: 'column',
                            marginBottom: { xs: '5%', lg: '10%' }
                        }}>
                        <CustomTypography sx={{
                            fontSize: { xs: '25px', md: '30px', lg: '42px' },
                            color: 'white',
                            fontFamily: 'Montserrat',
                            fontWeight: '600',
                        }}
                        >
                            Dr. Roger Nabarro
                        </CustomTypography>
                        <Divider sx={{ width: '80%', backgroundColor: 'white' }} />
                    </FlexContainer>
                    <CustomTypography
                        sx={{
                            fontSize: { lg: '18px' },
                            color: 'white',
                            textAlign: 'justify',
                            justifySelf: 'center',
                        }}>
                        Dr. Roger Nabarro é um Cirurgião-Dentista de renome,
                        formado pela prestigiada Universidade Federal Fluminense, em Niterói.
                        Especializando em Prótese e pós-graduando em Odontologia Estética pela mesma instituição,
                        ele é um profissional dedicado e apaixonado por transformar sorrisos.
                        Com mais de cinco artigos publicados em revistas científicas nacionais e
                        participação ativa em diversos congressos de Odontologia Estética e
                        Reabilitação Oral pelo Brasil, Dr. Roger se destaca por sua expertise e
                        atualização constante.
                    </CustomTypography>
                    <CustomTypography
                        sx={{
                            fontSize: { lg: '18px' },
                            marginTop: '5%',
                            marginBottom: { xs: '5%', lg: '5%', xl: '0%' },
                            color: 'white',
                            textAlign: 'justify',
                            justifySelf: 'center',
                        }}>
                        Oferece tratamentos de ponta com Resinas Compostas e Cerâmicas,
                        sempre com um enfoque minimamente invasivo. Seu compromisso é com a saúde oral
                        personalizada, oferecendo cuidados preventivos e reabilitadores que atendem às
                        necessidades específicas de cada paciente. Venha conhecer um atendimento diferenciado,
                        onde seu sorriso é tratado com o máximo de excelência e carinho.
                    </CustomTypography>
                </FlexContainer>
                <FlexContainer
                    sx={{
                        width: { xs: '90%', sm: '60%', md: '60%', lg: '60%', xl: '40%' },
                        alignSelf: 'center',
                        justifyContent: 'center'
                    }}>
                    <img
                        src={RogerImage}
                        loading="lazy"
                        style={{ width: '100%', borderRadius: 5 }}
                    />
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    )
}