import { FlexContainer } from "./ui/flex-container"
import MainVideo from '../assets/videos/main.webm'
import { CustomTypography } from "./ui/custom-typography"
import { WhatsappButton } from "./whatsapp/whatsapp-button"

export const VideoSection = () => {
    return (
        <FlexContainer
            sx={{
                height: 'auto',
                justifyContent: 'center',
                padding: '5% 5% 10% 5%',
                alignItems: 'center',
                background: 'linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(27,44,72,1) 115%)',
            }}
        >
            <FlexContainer
                sx={{
                    height: 'auto',
                    width: { xs: '95%', md: '900px', lg: '900px', xl: '900px' },
                    flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(27,44,72,1) 100%)',
                    padding: '5%',
                    color: 'white',
                    paddingX: '2%',
                    borderRadius: 5
                }}
            >
                <FlexContainer
                    width={{ xs: 'auto', sm: '50%' }}
                    marginBottom={{ xs: '10%', sm: '0%' }}
                >
                    <video
                        src={MainVideo}
                        autoPlay
                        autoFocus
                        muted
                        width={'100%'}
                        controls
                        loop
                    />
                </FlexContainer>
                <FlexContainer
                    sx={{
                        width: { xs: '90%', sm: '50%', xl: '50%' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: { xs: 'center', md: 'start' },
                        boxSizing: 'border-box',
                        paddingX: '4%',
                    }}
                >
                    <CustomTypography
                        sx={{
                            fontSize: { xs: 25, md: 25, lg: 25, xl: 25 },
                            fontWeight: 700,
                            textAlign: { xs: 'center', md: 'start' },
                            marginBottom: '5%',
                        }}
                    >
                        Venha conhecer nosso trabalho!
                    </CustomTypography>
                    <FlexContainer
                        width={{ xs: '80%', md: '65%' }}
                        alignSelf={{ xs: 'center', md: 'start', xl: 'start' }}
                    >
                        <CustomTypography
                            sx={{
                                fontSize: { xs: 15, md: 18, lg: 18, xl: 18 },
                                fontWeight: 400,
                                textAlign: { xs: 'center', md: 'start' },
                                marginBottom: '10%'
                            }}
                        >
                            Assista o vídeo e veja como são feitos alguns dos nossos procedimentos.
                        </CustomTypography>
                    </FlexContainer>
                    <WhatsappButton />
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>
    )
}